/* ReadReflection */
#ReadReflection_container {
    margin: auto;
    margin-top:150px;
    width: 60%;
    margin-bottom: 70px;
}

#ReadReflection_quote {
    font-size: 30px;
    font-family:  "Times New Roman", serif;
    font-style: italic;
}

#ReadReflection_bookContainer {
    font-family: "Source Sans Pro", sans-serif;
    letter-spacing: 0.7px;
    font-weight: 400;
    display: flex;
    border: solid 0.7px #a9a9a9;
    width: fit-content;
    border-radius: 15px;
    padding:20px;
    column-gap: 15px;
    margin-bottom:30px;
    align-items: center;
}

#ReadReflection_bookText{
    color: rgb(27, 27, 27);
    display: flex;
    flex-direction: column;
    gap: 5px;
}
#ReadReflection_bookImageContainer{
    height: fit-content;
    width: 80px;
}

#ReadReflection_bookImage {
    width: 80px;
    height: auto;
}

#ReadReflection_reflection {
  margin: auto;
  line-height: 1.5;
  font-size: 19px;
  font-family: 'Nanum Myeongjo', serif;
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  font-weight: 800;
}

  #ReadReflection_usernameDateContainer{
    display: flex;
    flex-direction: column;
    gap:5px;
    margin-bottom: 25px;
  }

  #ReadReflection_username{
    width: fit-content;

    font-family: "Source Sans Pro", sans-serif;
    font-weight: 500;
    font-size: 20px;
  }

  #ReadReflection_username:hover{
    width: fit-content;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 500;
    font-size: 20px;
    cursor: pointer;
    text-decoration: underline;
  }

  #ReadReflection_date{
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 0.2px;
    color: rgb(146, 146, 146);
}

@media only screen and (max-width:800px){
  #ReadReflection_container {
    margin: auto;
    margin-top:180px;
    width: 90vw;
    margin-bottom: 70px;
}
#ReadReflection_quote {
  font-size: 25px;
  font-family:  "Times New Roman", serif;
  font-style: italic;
}

#ReadReflection_reflection {
  margin: auto;
  line-height: 1.5;
  font-size: 18px;
  font-family: 'Nanum Myeongjo', serif;
  letter-spacing: 0.2px;
  white-space: pre-wrap;
  font-weight: 800;
}
}