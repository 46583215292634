#LogIn_container {
    height: 90vh;
    width: fit-content;
    margin: auto;
    margin-top: 150px;
  }

#LogIn_heading {
  font-family: 'Source Sans Pro', sans-serif;
    font-size:44px;
    font-weight: 400;
    margin-bottom: 15px;
    text-align: center;
}

.LogIn_input {
  width: 15vw;
  font-size: 15px;
  outline: none;
  padding: 10px 15px 10px 15px;
  background-color: white;
  margin: 0px auto;
  border: 0.7px solid rgb(180, 180, 180);
  border-radius: 10px;
  margin-top: 7px;
  margin-bottom: 7px;
}

#LogIn_button {
  background-color: rgb(231, 231, 231); 
  border: none;
  border-radius: 25px;
  color: rgb(0, 0, 0);
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 0.5px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 5px;
}

#LogIn_button:hover {
  background-color: #f0f0f0; 
  border-radius: 25px;
  border: none;
  color: rgb(0, 0, 0);
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 0.5px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 5px;
}

@media only screen and (max-width:800px){
  #LogIn_container {
    height: 90vh;
    width: fit-content;
    margin: auto;
    margin-top: 120px;
  }

  .LogIn_input {
    width: 70vw;
    font-size: 15px;
    outline: none;
    padding: 10px 15px 10px 15px;
    background-color: white;
    margin: 0px auto;
    border: 0.7px solid rgb(180, 180, 180);
    border-radius: 10px;
    margin-top: 7px;
    margin-bottom: 7px;
  }
}