p, h1 {
    font-family: sans-serif;
    margin:0;
}

#divider {
    height: "0.5px";
    background-color: #c7c7c7;
}

body {
    margin:0;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&family=Nanum+Myeongjo:wght@400;700&family=Source+Sans+Pro:wght@400;600;700&display=swap');

/* UniversalFixedNav */
#UniversalFixedNav_navContainer {
    position: fixed;
    top: 0;
    width: 100%;
    padding-bottom: 25px;
    padding-top: 25px;
    border-bottom: 0.7px solid rgb(180, 180, 180);
    background-color: white;
  }

#UniversalFixedNav_nav {
    height: 100%;
    width: 90vw;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

#UniversalFixedNav_logo {
    width:fit-content;
    cursor: pointer;
    font-size: 35px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    letter-spacing: 2px;
}

#UniversalFixedNav_reflectButton {
    background-color: rgb(255, 255, 255); 
    border: none;
    border-radius: 25px;
    color: rgb(11, 175, 38);
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    font-family: "Source Sans Pro", sans-serif;
    letter-spacing: 0.5px;
    font-weight: 600;
    cursor: pointer;
  }

  #UniversalFixedNav_reflectButton:hover {
    background-color: rgb(222, 245, 226); 
    border-radius: 25px;
    border: none;
    color: rgb(11, 175, 38);
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    font-family: "Source Sans Pro", sans-serif;
    letter-spacing: 0.5px;
    font-weight: 600;
    cursor: pointer;
  }

  .UniversalFixedNav_button {
    background-color: rgb(255, 255, 255); 
    border: none;
    border-radius: 25px;
    color: rgb(0, 0, 0);
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    font-family: "Source Sans Pro", sans-serif;
    letter-spacing: 0.5px;
    font-weight: 600;
    cursor: pointer;
  }

  .UniversalFixedNav_button:hover {
    background-color: #f0f0f0; 
    border-radius: 25px;
    border: none;
    color: rgb(0, 0, 0);
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    font-family: "Source Sans Pro", sans-serif;
    letter-spacing: 0.5px;
    font-weight: 600;
    cursor: pointer;
  }


  @media only screen and (max-width:800px){
    #UniversalFixedNav_logo {
      width:fit-content;
      cursor: pointer;
      font-size: 25px;
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      letter-spacing: 2px;
  }

    #UniversalFixedNav_navContainer {
      position: fixed;
      top: 0;
      width: 100%;
      padding-bottom: 10px;
      padding-top: 8px;
      border-bottom: 0.7px solid rgb(180, 180, 180);
      background-color: white;
    }

    #UniversalFixedNav_nav {
      height: 100%;
      width: 90vw;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
    .UniversalFixedNav_buttonContainer {
      display: none;
      flex-direction: column;
    }

    #UniversalFixedNav_reflectButton {
      background-color: rgb(255, 255, 255); 
      border: none;
      border-radius: 25px;
      color: rgb(11, 175, 38);
      padding: 10px 20px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 20px;
      font-family: "Source Sans Pro", sans-serif;
      letter-spacing: 0.5px;
      font-weight: 600;
      cursor: pointer;
    }
  
    #UniversalFixedNav_reflectButton:hover {
      background-color: rgb(222, 245, 226); 
      border-radius: 25px;
      border: none;
      color: rgb(11, 175, 38);
      padding: 10px 20px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 20px;
      font-family: "Source Sans Pro", sans-serif;
      letter-spacing: 0.5px;
      font-weight: 600;
      cursor: pointer;
    }

    .UniversalFixedNav_button {
      background-color: rgb(255, 255, 255); 
      border: none;
      border-radius: 25px;
      color: rgb(0, 0, 0);
      padding: 10px 20px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 20px;
      font-family: "Source Sans Pro", sans-serif;
      letter-spacing: 0.5px;
      font-weight: 600;
      cursor: pointer;
    }
  
    .UniversalFixedNav_button:hover {
      background-color: rgb(255, 255, 255); 
      border: none;
      border-radius: 25px;
      color: rgb(0, 0, 0);
      padding: 10px 20px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 20px;
      font-family: "Source Sans Pro", sans-serif;
      letter-spacing: 0.5px;
      font-weight: 600;
      cursor: pointer;
    }
  }

/* SearchBar */
#SearchBarContainer {
    width:fit-content;
    margin: auto;
}

#SearchBar_searchInput {
    width: 30vw;
    font-size: 15px;
    outline: none;
    padding: 13px 25px 13px 25px;
    background-color: white;
    margin: 0px auto;
    border: 0.7px solid rgb(180, 180, 180);
    border-radius: 25px;
}

#SearchBar_results {
    position: absolute;
    height:150px;
    width: 33vw;
    overflow: hidden;
    overflow-y: auto;
    border-radius:25px;
    background-color: white;
    cursor: pointer;
    margin: auto;
    margin-top: 7px;
    border: 0.7px solid rgb(180, 180, 180);
    font-family: "Source Sans Pro", sans-serif;
    letter-spacing: 0.3px;
}

#SearchBarListItem:hover {
    background-color: rgb(244, 244, 244);
    padding:15px;
}

#SearchBarListItem{
    text-align: left;
    padding:15px;

}

@media only screen and (max-width:800px){
  #SearchBar_searchInput {
    width: 70vw;
    font-size: 15px;
    outline: none;
    padding: 13px 25px 13px 25px;
    background-color: white;
    margin-top: 15px;
    margin-bottom: 5px;
    border: 0.7px solid rgb(180, 180, 180);
    border-radius: 25px;
  }

#SearchBar_results {
    position: absolute;
    height:150px;
    width: 82vw;
    overflow: hidden;
    overflow-y: auto;
    border-radius:25px;
    background-color: white;
    cursor: pointer;
    margin: auto;
    margin-top: 7px;
    border: 0.7px solid rgb(180, 180, 180);
    font-family: "Source Sans Pro", sans-serif;
    letter-spacing: 0.3px;
  }
}

#UniversalFixedNav_toggle {
  display: none;
}

.UniversalFixedNav_toggleBar {
  height: 3px;
  width: 100%;
  background-color: black;
  border-radius: 2.5px;
}

@media only screen and (max-width:800px){
  #UniversalFixedNav_toggle {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: space-between;
    height: 16px;
    width: 22px;
    top:15px;
    right: 25px;
  }
  .UniversalFixedNav_inactiveMenuNav{
    display: none;
  }

}