/* SearchResults */
#SearchResults_container {
    height: 90vh;
    width: 70vw;
    margin: auto;
    margin-top: 150px;
    }

#SearchResults_heading {
    font-family: 'Source Sans Pro', sans-serif;
    font-size:44px;
    font-weight: 400;
    margin-bottom: 25px;
    line-height: 1.5;
}

@media only screen and (max-width: 800px) {
    #SearchResults_container {
        height: 90vh;
        width: 90vw;
        margin: auto;
        margin-top: 160px;
        }

    #SearchResults_heading {
        font-family: 'Source Sans Pro', sans-serif;
        font-size:33px;
        font-weight: 400;
        margin-bottom: 25px;
        line-height: 1.5;
    }
}



/* SearchResultsItem */

#SearchResultsItem_container {
    height: fit-content;
   }

#SearchResultsItem_divider {
    height: 0.5px;
    background-color: #c7c7c7;
    margin-top: 25px;
    margin-bottom: 25px;
   }

#SearchResultsItem_quote {
      font-family:'Times New Roman', Times, serif;
      font-size: 24px;
      font-style: italic;
      border-left: 3px solid black;
      padding-left: 5px;
      margin-bottom: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 1.5;
    }

#SearchResultsItem_contentStyle {
      font-family: 'Nanum Myeongjo', serif;
      font-size:16px;
      font-weight: 800;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 1.5;
    }
#SearchResultsItem_username {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
    }

#SearchResultsItem_username:hover {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
    text-decoration: underline;
    }

#SearchResultsItem_usernameDateFlex {
    display: flex;
    align-items: center;
}

#SearchResultsItem_date {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
    margin-left:2px;
    color: rgb(124, 124, 124);
}

@media only screen and (max-width: 800px) {
    #SearchResultsItem_contentStyle {
        font-family: 'Nanum Myeongjo', serif;
        font-size:16px;
        font-weight: 800;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        line-height: 1.5;
      }
}