/* BookAdder */
#BookAdder_containter {
    width:fit-content;
    margin-bottom: 50px;       
}

/* BookAdderSearchBar */
#BookAdderSearchBar_searchInput {
  width: 30vw;
  font-size: 15px;
  outline: none;
  padding: 13px 25px 13px 25px;
  background-color: white;
  margin: 0px auto;
  border: 0.7px solid rgb(180, 180, 180);
  border-radius: 25px;
}

#BookAdderSearchBar_results {
  position: absolute;
  height:150px;
  width: 33vw;
  overflow: hidden;
  overflow-y: auto;
  border-radius:25px;
  background-color: white;
  cursor: pointer;
  margin: auto;
  margin-top: 7px;
  border: 0.7px solid rgb(180, 180, 180);
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 0.3px;
}

@media only screen and (max-width:800px){
  #BookAdderSearchBar_searchInput {
    width: 70vw;
    font-size: 15px;
    outline: none;
    padding: 13px 25px 13px 25px;
    background-color: white;
    margin: 0px auto;
    border: 0.7px solid rgb(180, 180, 180);
    border-radius: 25px;
  }
  
  #BookAdderSearchBar_results {
    position: absolute;
    height:150px;
    width: 75vw;
    overflow: hidden;
    overflow-y: auto;
    border-radius:25px;
    background-color: white;
    cursor: pointer;
    margin: auto;
    margin-top: 7px;
    border: 0.7px solid rgb(180, 180, 180);
    font-family: "Source Sans Pro", sans-serif;
    letter-spacing: 0.3px;
  }
}

/* BookAdderListItem */
#BookAdderListItem:hover {
  background-color: rgb(244, 244, 244);
  padding:15px;
}

#BookAdderListItem{
  text-align: left;
  padding:15px;

}

/* BookAdderSelection */
#BookAdderSelection_bookContainer {
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 0.7px;
  font-weight: 400;
  display: flex;
  border: solid 0.7px #a9a9a9;
  width: fit-content;
  border-radius: 15px;
  padding:20px;
  column-gap: 15px;
  margin-bottom:30px;
  align-items: center;
}



#BookAdderSelection_bookImageContainer{
  height: fit-content;
  width: 80px;
}

#BookAdderSelection_bookImage {
  width: 80px;
  height: auto;
}

#BookAdderSelection_bookText{
  color: rgb(27, 27, 27);
  display: flex;
  flex-direction: column;
  gap: 5px;
}

#BookAdderSelection_changeButton {
  background-color: rgb(255, 255, 255); 
  border: none;
  border-radius: 25px;
  color: rgb(0, 0, 0);
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 0.5px;
  font-weight: 600;
  cursor: pointer;
}

#BookAdderSelection_changeButton:hover {
  background-color: #f0f0f0; 
  border-radius: 25px;
  border: none;
  color: rgb(0, 0, 0);
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 0.5px;
  font-weight: 600;
  cursor: pointer;
}
@media only screen and (max-width:800px){
  #BookAdderSelection_bookContainer {
    font-family: "Source Sans Pro", sans-serif;
    letter-spacing: 0.7px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    border: solid 0.7px #a9a9a9;
    width: fit-content;
    border-radius: 15px;
    padding:20px;
    gap: 15px;
    margin-bottom:30px;
    align-items: center;
  }
}
/* CreateReflection */
#CreateReflection_container {
    height: 90vh;
    width: 70vw;
    margin: auto;
    margin-top: 150px;
  }

#CreateReflection_reflectionField {
    margin-top: 10vh;
    height: fit-content;
    width: 100%;
    font-size: 21px;
    outline: none;
    border: none;
    overflow: auto;
    resize: none;
    margin: auto;
    line-height: 1.7em;
    letter-spacing: 0.2px;
    font-family: "Nanum Myeongjo", serif;
    font-weight: 800;
  }

#CreateReflection_nav {
    position: fixed;
    top: 0;
    width: 100%;
    height: 50px;
    background-color: gray;
  }

#CreateReflection_quoteField {
    margin: auto;
    margin-bottom: 10px;
    width: 100%;
    outline: none;
    border: none;
    overflow: auto;
    resize: none;
    font-size: 30px;
    font-family:  "Times New Roman", serif;
    font-style: italic;
    border-left: 3px solid black;
    padding-left: 20px;
}

@media only screen and (max-width:800px){
  #CreateReflection_container {
    height: 90vh;
    width: 90vw;
    margin: auto;
    margin-top: 70px;
  }
  #CreateReflection_quoteField {
    margin: auto;
    margin-bottom: 10px;
    width: 100%;
    outline: none;
    border: none;
    overflow: auto;
    resize: none;
    font-size: 25px;
    font-family:  "Times New Roman", serif;
    font-style: italic;
    border-left: 3px solid black;
    padding-left: 20px;
}
  #CreateReflection_reflectionField {
    margin-top: 10vh;
    height: fit-content;
    width: 100%;
    font-size: 18px;
    outline: none;
    border: none;
    overflow: auto;
    resize: none;
    margin: auto;
    line-height: 1.7em;
    letter-spacing: 0.2px;
    font-family: "Nanum Myeongjo", serif;
    font-weight:800;
  }
}




#PublishNav_container {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100px;
    border-bottom: 0.7px solid rgb(180, 180, 180);
    background-color: white;
  }

  #PublishNav_nav {
    height:100%;
    width: 90vw;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #PublishNav_logo {
    width:fit-content;
    cursor: pointer;
    font-size: 35px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    letter-spacing: 2px;
}

#PublishNav_publishButton {
  background-color: rgb(255, 255, 255); 
  border: none;
  border-radius: 25px;
  color: rgb(11, 175, 38);
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 0.5px;
  font-weight: 600;
  cursor: pointer;
}

#PublishNav_publishButton:hover {
  background-color: rgb(222, 245, 226); 
  border-radius: 25px;
  border: none;
  color: rgb(11, 175, 38);
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 0.5px;
  font-weight: 600;
  cursor: pointer;
}

@media only screen and (max-width:800px){
  #PublishNav_container {
    position: fixed;
    top: 0;
    width: 100%;
    height: fit-content;
    border-bottom: 0.7px solid rgb(180, 180, 180);
    background-color: white;
  }

  #PublishNav_logo {
    width:fit-content;
    cursor: pointer;
    font-size: 25px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    letter-spacing: 2px;
}

  #PublishNav_publishButton {
    background-color: rgb(255, 255, 255); 
    border: none;
    border-radius: 25px;
    color: rgb(11, 175, 38);
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    font-family: "Source Sans Pro", sans-serif;
    letter-spacing: 0.5px;
    font-weight: 600;
    cursor: pointer;
  }
  
  #PublishNav_publishButton:hover {
    background-color: rgb(222, 245, 226); 
    border-radius: 25px;
    border: none;
    color: rgb(11, 175, 38);
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    font-family: "Source Sans Pro", sans-serif;
    letter-spacing: 0.5px;
    font-weight: 600;
    cursor: pointer;
  }
}