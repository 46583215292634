#Settings_container {
    margin: auto;
    margin-top:150px;
    width: 60%;
    margin-bottom: 70px;
}

#Settings_row {
    display: flex;
    justify-content: space-between;
}

#Settings_divider {
    height: 0.5px;
    background-color: #c7c7c7;
}