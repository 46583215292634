/* Home */
#Home_container {
    width: 100%;
    margin-top: 100px;
}

#Home_contentContainer {
    margin-top: 200px;
    text-align: center;
  }

#Home_descPopUp {
  position: absolute;
  text-align: left;
  padding: 15px;
  color: white;
  background-color: rgb(41, 124, 233);
  font-family: "Source Sans Pro", sans-serif;
  margin: 15px;
  top: 0;
  max-width: 450px;
  border-radius: 10px;
  cursor: pointer;
}
  
#Home_siteName {
    font-size: 90px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    letter-spacing: 2px;
    margin-bottom: 25px;
  }

  @media only screen and (max-width: 800px) {
    #Home_contentContainer {
      margin-top: 100px;
      text-align: center;
    }
    #Home_siteName {
      display: none;
      font-size: 60px;
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      letter-spacing: 2px;
      margin-bottom: 25px;
    }

  }
